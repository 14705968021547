import { ThemeContext } from 'contexts/ThemeContext'
import React, { useContext, useState } from 'react'
import { Moon, Sun } from 'react-bootstrap-icons'
import Button from 'react-bootstrap/Button'

function ThemeToggle() {
  const { theme, setTheme } = useContext(ThemeContext)
  const [lightTheme, setLightTheme] = useState(theme === 'light')
  const [buttonVariant, setButtonVariant] = useState(theme === 'light' ? 'dark' : 'dark')
  const [icon, setIcon] = useState(theme === 'light' ? <Sun /> : <Moon />)

  const toggleTheme = () => {
    const newValue = !lightTheme
    setLightTheme(newValue)
    const theme = newValue ? 'light' : 'dark'
    setTheme(theme)
  }

  const handleMouseEnter = () => {
    setButtonVariant(lightTheme ? 'info' : 'info')
    setIcon(lightTheme ? <Moon /> : <Sun />)
  }

  const handleMouseLeave = () => {
    setButtonVariant(theme === 'light' ? 'dark' : 'dark')
    setIcon(lightTheme ? <Sun /> : <Moon />)
  }

  return (
    <Button
      style={{ borderRadius: '50%' }}
      variant={buttonVariant}
      onClick={toggleTheme}
      onMouseOver={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {icon}
    </Button>
  )
}

export default ThemeToggle
