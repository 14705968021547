import config from 'config'
import { getJsonHeaders, getQueryStringFromFiltersObject } from './helpers'

export async function getStats(id, query = {}) {
  let url = config.API_URL + `/iapi/reports/home/${id}`

  const queryString = getQueryStringFromFiltersObject(query)

  if (queryString) {
    url += `?${queryString}`
  }

  const result = await fetch(url, {
    headers: await getJsonHeaders(),
  })

  return {
    success: result.status === 200,
    status: result.status,
    body: await result.json(),
  }
}
