import React from 'react'
import Container from 'react-bootstrap/Container'
import styles from './Unauth.module.scss'

export default function Layout({ component: C, ...props }) {
  return (
    <Container className={styles.container} fluid>
      <C {...props} />
    </Container>
  )
}
