import config from 'config'

const getCookieValue = name => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    const ret = parts.pop().split(';').shift()
    return decodeURIComponent(ret)
  }

  return ''
}

export async function getFormDataHeaders() {
  return {
    'X-XSRF-TOKEN': await getCookie('XSRF-TOKEN'),
  }
}

export async function getJsonHeaders() {
  return {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-XSRF-TOKEN': await getCookie('XSRF-TOKEN'),
  }
}

export async function getCookie(name) {
  // attempt to get cookie value
  const ret = getCookieValue(name)

  // if there is a value return it
  if (ret) return ret

  // otherwise make a get request so any missing cookie can be set
  // for example, this will set XSRF-TOKEN if missing
  await fetch(config.API_URL + '/iapi/csrf')

  // try and get the value again
  return getCookieValue(name)
}

export function getQueryStringFromFiltersObject(filters) {
  const filterString = Object.entries(filters)
    .filter(entry => {
      return entry[1].length
    })
    .map(entry => {
      const [key, value] = entry
      return `${key}=${value}`
    })
    .join('&')

  return filterString
}
