import React, { useEffect, useState } from 'react'

const PrivateModeContext = React.createContext()
const WithPrivateMode = ({ children }) => {
  const [privateModeEnabled, setPrivateModeEnabled] = useState(true)

  useEffect(() => {
    const pmen = localStorage.getItem('pmen')
    setPrivateModeEnabled(parseInt(pmen) === 0 ? false : true)
  }, [])

  const togglePrivateModeEnabled = val => {
    const newValue = !privateModeEnabled
    localStorage.setItem('pmen', newValue ? 1 : 0)
    setPrivateModeEnabled(newValue)
  }

  const defaultContext = {
    privateModeEnabled,
    togglePrivateModeEnabled,
  }

  return (
    <PrivateModeContext.Provider value={defaultContext}>
      {children}
    </PrivateModeContext.Provider>
  )
}

export { PrivateModeContext }
export default WithPrivateMode
