import { deleteXeroConnection, disconnectXero, getXeroConnections } from 'api/auth'
import xeroButton from 'assets/xero/connect-blue.svg'
import xeroDisconnect from 'assets/xero/disconnect-blue.svg'
import Loader from 'components/Loader'
import Panel from 'components/Panel'
import config from 'config'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import { Helmet } from 'react-helmet'

const initialXeroConnections = {
  success: null,
  body: [],
}

function MyAccount({ history }) {
  const { user, setAuthenticated, setUser, handleLogout } = useContext(AuthContext)

  const [xeroConnections, setXeroConnections] = useState(initialXeroConnections)

  useEffect(() => {
    getXeroConnections().then(x => {
      if (!x.success) {
        handleLogout()
        history.push({ pathname: '/' })
      } else {
        setXeroConnections(x)
      }
    })
  }, [history, handleLogout])

  const handleDisconnect = () => {
    disconnectXero()
    setAuthenticated(false)
    setUser(null)
  }

  const handleDeleteConnection = connectionId => {
    setXeroConnections(initialXeroConnections)
    deleteXeroConnection(connectionId).then(() =>
      getXeroConnections().then(x => setXeroConnections(x))
    )
  }

  if (xeroConnections.success === null) return <Loader />

  return (
    <Container>
      <Helmet>
        <title>Moolah - My Account</title>
      </Helmet>

      <Row>
        <Col xl={{ span: 6, offset: 3 }}>
          <Panel className='mb-4'>
            <Table style={{ width: '100%', wordWrap: 'break-word' }}>
              <tbody>
                <tr>
                  <td>
                    <strong>Account Reference</strong>
                  </td>
                  <td>{user.reference}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Account Name</strong>
                  </td>
                  <td>
                    {user.first_name} {user.last_name}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Account Created</strong>
                  </td>
                  <td>{user.created}</td>
                </tr>
              </tbody>
            </Table>
          </Panel>

          <h5>Xero Connections</h5>
          <Panel className='mb-4'>
            {xeroConnections.body.length > 1 && (
              <div className='pb-5'>
                You only need one connection to use Moolah, if you'd like to remove a
                connection you can do that using the below disconnect from Xero buttons.
              </div>
            )}

            {xeroConnections.body.map(x => (
              <Row className='align-items-center'>
                <Col className='mb-3' md={6}>
                  {x.tenant_name}
                </Col>
                <Col className='mb-3' md={6}>
                  {xeroConnections.body.length > 1 ? (
                    <img
                      className='cursor-pointer'
                      style={{ maxWidth: '100%' }}
                      onClick={() => handleDeleteConnection(x.id)}
                      src={xeroDisconnect}
                      alt='connect'
                    />
                  ) : (
                    <img
                      className='cursor-pointer'
                      onClick={() => handleDisconnect()}
                      src={xeroDisconnect}
                      alt='connect'
                    />
                  )}
                </Col>
              </Row>
            ))}

            <Row className='align-items-center'>
              <Col className='mb-3' md={6}>
                Connect another organisation.
              </Col>
              <Col className='mb-3' md={6}>
                <a className='w-100' href='/xero/auth/authorize'>
                  <img
                    src={xeroButton}
                    className='cursor-pointer'
                    style={{ maxWidth: '100%' }}
                    alt='connect'
                  />
                </a>
              </Col>
            </Row>
          </Panel>

          <p className='text-center pt-4'>
            <a
              style={{ color: 'white' }}
              href={config.XERO_PRICING_PLAN_URL}
              target='_blank'
              rel='noopener noreferrer'
            >
              Click here to manage your Moolah subscription with Xero.
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  )
}

export default MyAccount
