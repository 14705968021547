import React, { useEffect, useState } from 'react'

const defaultTheme = 'dark'

const getTopbarBg = theme => {
  switch (theme) {
    case 'light':
      return 'dark'
    default:
      return 'dark'
  }
}

const ThemeContext = React.createContext()
const WithTheme = ({ children }) => {
  const [theme, setThemeName] = useState(defaultTheme)
  const [topbarBg, setTopbarBg] = useState(getTopbarBg(defaultTheme))

  useEffect(() => {
    const _theme = localStorage.getItem('theme')
      ? localStorage.getItem('theme')
      : defaultTheme
    setThemeName(_theme)
    setTopbarBg(getTopbarBg(_theme))
    document.documentElement.setAttribute('data-theme', _theme)
  }, [])

  const setTheme = theme => {
    setThemeName(theme)
    setTopbarBg(getTopbarBg(theme))
    localStorage.setItem('theme', theme)
    document.documentElement.setAttribute('data-theme', theme)
  }

  const setTopbarTransparentBg = () => {
    setTopbarBg(`${getTopbarBg(theme)}-md-transparent`)
  }

  const resetTopbarBg = () => {
    setTopbarBg(getTopbarBg(theme))
  }

  const defaultContext = {
    theme,
    setTheme,
    topbarBg,
    setTopbarTransparentBg,
    resetTopbarBg,
  }
  return <ThemeContext.Provider value={defaultContext}>{children}</ThemeContext.Provider>
}

export { ThemeContext }
export default WithTheme
