import React from 'react'
import styles from './Login.module.scss'
import xeroButton from 'assets/xero/connect-blue.svg'
import { Col } from 'react-bootstrap'
import connectedAppImg from 'assets/xero/connected-app.png'
import xeroDeveloper from 'assets/xero/xero-developer.png'

function Login() {
  return (
    <div className='h-100'>
      <div className={styles.wrapper}>
        <div className={`${styles.login} px-4`}>
          <div className='mb-4'>
            <img src='/logo-light.svg' alt='Logo' className='me-3' />
          </div>

          <div className='mb-5 px-xl-4'>
            <Col sm={{ span: 8, offset: 2 }} md={{ span: 4, offset: 4 }}>
              <h4>New to Moolah?</h4>
              <p>
                Connecting to Xero will start your free Moolah trial and give you access
                to premium Moolah features.
              </p>

              <h4>Used Moolah Before?</h4>
              <p>Connecting to Xero will log you in to your existing Moolah account.</p>
            </Col>
          </div>

          <div className='pb-5'>
            <a href='/xero/auth/authorize'>
              <img
                style={{ width: '70vw', maxWidth: '240px', minWidth: '200px' }}
                src={xeroButton}
                alt='connect'
              />
            </a>
          </div>

          <div className={styles.push}></div>
        </div>
      </div>

      <div className={styles.footer}>
        <img height={100} src={connectedAppImg} alt='Xero connected app' />
        <img height={100} src={xeroDeveloper} alt='Xero developer' />
      </div>
    </div>
  )
}

export default Login
