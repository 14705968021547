import { getStats, getInvoiceStatsByDateRange } from 'api/home'
import Error from 'components/Error'
import Loader from 'components/Loader'
import Panel from 'components/Panel'
import PrivateContent from 'components/PrivateContent'
import SubscriptionBanner from 'components/SubcriptionBanner'
import SyncNow from 'components/SyncNow'
import React, { useCallback, useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import { Helmet } from 'react-helmet'
import { exTax, incTax, tax } from 'terminology/invoices'
import useFitText from 'use-fit-text'
import RelativeLoader from 'components/RelativeLoader'
import { Alert } from 'react-bootstrap'

const initialStats = {
  success: null,
  body: {
    free_trial: '',
    subscribed: '',
    last_sync: '',
    can_resync: false,
    tenant_id: '',
    currency: '',
    country_code: '',
    connections: [],
    currencies: [],
    repeating_invoices: {
      total_invoice_count: '',
      invoice_limit: '',
      last_month: {
        count: '',
        total: '',
        total_tax: '',
        sub_total: '',
        sub_total_diff: '',
      },
      this_month: {
        count: '',
        total: '',
        total_tax: '',
        sub_total: '',
        sub_total_diff: '',
      },
      next_month: {
        count: '',
        total: '',
        total_tax: '',
        sub_total: '',
        sub_total_diff: '',
      },
      ending_soon: {
        count: '',
        total: '',
        total_tax: '',
        sub_total: '',
      },
      ended: {
        count: '',
        total: '',
        total_tax: '',
        sub_total: '',
      },
      contacts: {
        last_month: [],
        this_month: [],
        next_month: [],
        ending_soon: [],
        ended: [],
      },
      account_codes: {
        last_month: [],
        this_month: [],
        next_month: [],
        ending_soon: [],
        ended: [],
      },
    },
  },
}

function Monthly({ history, location }) {
  const [error, setError] = useState(false)
  const [syncing, setSyncing] = useState(false)
  const [tenantId, setTenantId] = useState('')
  const [currency, setCurrency] = useState('')
  const [stats, setStats] = useState(initialStats)

  const handleSync = useCallback(async () => {
    setSyncing(true)
    const res = await getStats({ tenant_id: tenantId, currency: currency, sync: 'yes' })

    if (!res.success) {
      setError(true)
      history.push({ pathname: '/' })
    } else {
      setSyncing(false)
      setStats(res)
    }
  }, [tenantId, currency, history])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const _tenantId = queryParams.get('tenantId') ?? tenantId
    const _currency = currency
    setTenantId(_tenantId)

    setStats(initialStats)
    getStats({ tenant_id: _tenantId, currency: _currency }).then(x => {
      if (!x.success) {
        setError(true)
        history.push({ pathname: '/' })
      } else {
        setStats(x)
        if (x.body.should_resync) {
          handleSync()
        }
      }
    })
  }, [tenantId, currency, history, handleSync, location.search])

  const handleChangeTenant = e => {
    history.push({ pathname: '', search: `tenantId=${e.target.value}` })
  }

  const handleChangeCurrency = e => {
    const val = e.target.value
    setCurrency(val)
  }

  const planLimitReached = () => {
    if (stats.body?.repeating_invoices ?? false) {
      return (
        stats.body.repeating_invoices.invoice_limit !== null &&
        stats.body.repeating_invoices.total_invoice_count >
          stats.body.repeating_invoices.invoice_limit
      )
    }

    return false
  }

  if (error) return <Error />

  if (stats.success === null) return <Loader />

  return (
    <>
      <SubscriptionBanner
        subscribed={stats.body.subscribed}
        freeTrial={stats.body.free_trial}
        planLimitReached={planLimitReached()}
      />

      <Container>
        <Helmet>
          <title>Moolah - Home</title>
        </Helmet>

        <h1 className='mb-3'>Monthly Repeating Invoices</h1>

        <SyncNow
          lastSync={stats.body.last_sync}
          showSyncButton={stats.body.can_resync}
          doSync={handleSync}
          defaultSyncing={syncing}
        />

        {!stats.body.repeating_invoices ? (
          <Loader />
        ) : (
          <>
            <Row>
              <Col sm={6} className='mb-3'>
                <Form.Select
                  className='mr-4'
                  disabled={stats.body.connections.length <= 1}
                  value={stats.body.tenant_id}
                  onChange={handleChangeTenant}
                >
                  {stats.body.connections.map(x => (
                    <option key={x.tenant_id} value={x.tenant_id}>
                      {x.tenant_name} Invoices
                    </option>
                  ))}
                </Form.Select>
              </Col>

              <Col sm={6} className='mb-3'>
                <Form.Select
                  className='ml-4'
                  disabled={stats.body.currencies.length <= 1}
                  value={stats.body.currency}
                  onChange={handleChangeCurrency}
                >
                  {stats.body.currencies.map(x => (
                    <option key={x.code} value={x.code}>
                      {x.code} Invoices
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>

            <Accordion defaultActiveKey='this_month'>
              <Accordion.Item eventKey='this_month'>
                <Accordion.Header>This Month</Accordion.Header>
                <Accordion.Body>
                  <RepeatingInvoices
                    countryCode={stats.body.country_code}
                    data={stats.body.repeating_invoices.this_month}
                    contacts={stats.body.repeating_invoices.contacts.this_month}
                    accountCodes={stats.body.repeating_invoices.account_codes.this_month}
                  />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey='last_month'>
                <Accordion.Header>Last Month</Accordion.Header>
                <Accordion.Body>
                  <RepeatingInvoices
                    countryCode={stats.body.country_code}
                    data={stats.body.repeating_invoices.last_month}
                    contacts={stats.body.repeating_invoices.contacts.last_month}
                    accountCodes={stats.body.repeating_invoices.account_codes.last_month}
                  />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey='next_month'>
                <Accordion.Header>Next Month</Accordion.Header>
                <Accordion.Body>
                  <RepeatingInvoices
                    countryCode={stats.body.country_code}
                    data={stats.body.repeating_invoices.next_month}
                    contacts={stats.body.repeating_invoices.contacts.next_month}
                    accountCodes={stats.body.repeating_invoices.account_codes.next_month}
                  />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey='endingsoon'>
                <Accordion.Header>Ending Soon</Accordion.Header>
                <Accordion.Body>
                  <div className='mb-3 text-muted'>
                    <small>Repeating invoices ending within the next 3 months.</small>
                  </div>
                  <RepeatingInvoices
                    countryCode={stats.body.country_code}
                    data={stats.body.repeating_invoices.ending_soon}
                    contacts={stats.body.repeating_invoices.contacts.ending_soon}
                    accountCodes={stats.body.repeating_invoices.account_codes.ending_soon}
                  />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey='ended'>
                <Accordion.Header>Ended</Accordion.Header>
                <Accordion.Body>
                  <RepeatingInvoices
                    countryCode={stats.body.country_code}
                    data={stats.body.repeating_invoices.ended}
                    contacts={stats.body.repeating_invoices.contacts.ended}
                    accountCodes={stats.body.repeating_invoices.account_codes.ended}
                  />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey='custom'>
                <Accordion.Header>Custom Selection</Accordion.Header>
                <Accordion.Body>
                  <CustomSelection tenantId={tenantId} currency={currency} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>
        )}
      </Container>
    </>
  )
}

function CustomSelection({ tenantId, currency }) {
  const [month, setMonth] = useState('1')
  const [year, setYear] = useState('')
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleLoadData = async e => {
    e.preventDefault()

    if (isNaN(year) || year.length !== 4) {
      setError('Please enter a valid year in the format YYYY')
    } else {
      setLoading(true)
      const res = await getInvoiceStatsByDateRange(year, month, {
        tenant_id: tenantId,
        currency: currency,
      })

      if (res.success) {
        setData(res)
      } else {
        setError('Failed to load data')
      }

      setLoading(false)
    }
  }

  const handleChangeMonth = e => {
    setError('')
    setData()
    setMonth(e.target.value)
  }

  const handleChangeYear = e => {
    setError('')
    setData()
    setYear(e.target.value)
  }

  return (
    <div>
      <Form onSubmit={handleLoadData}>
        <Row>
          <Col sm={5} className='mb-3'>
            <Form.Select className='mr-4' value={month} onChange={handleChangeMonth}>
              <option value='1'>January</option>
              <option value='2'>February</option>
              <option value='3'>March</option>
              <option value='4'>April</option>
              <option value='5'>May</option>
              <option value='6'>June</option>
              <option value='7'>July</option>
              <option value='8'>August</option>
              <option value='9'>September</option>
              <option value='10'>October</option>
              <option value='11'>November</option>
              <option value='12'>December</option>
            </Form.Select>
          </Col>

          <Col sm={5} className='mb-3'>
            <Form.Control
              maxLength={4}
              onChange={handleChangeYear}
              placeholder='Enter year (YYYY), for example 2021'
            />
          </Col>

          <Col sm={2} style={{ textAlign: 'right' }}>
            <Button
              type='submit'
              disabled={loading}
              variant='outline-secondary'
              size='sm'
            >
              Load Data
            </Button>
          </Col>
        </Row>
      </Form>

      {error && <Alert variant='danger'>{error}</Alert>}

      {loading ? (
        <RelativeLoader />
      ) : (
        <>
          {data && (
            <RepeatingInvoices
              countryCode={data.body.country_code}
              data={data.body}
              contacts={data.body.contacts}
              accountCodes={data.body.account_codes}
            />
          )}
        </>
      )}
    </div>
  )
}

function RepeatingInvoices({ countryCode, data, contacts, accountCodes }) {
  return (
    <>
      <Row>
        <HeadlineCol>
          <Panel>
            <h5>Total {exTax(countryCode)}</h5>
            <PrivateContent>
              <PanelStat
                text={`${data.sub_total.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}`}
              />
            </PrivateContent>
          </Panel>
        </HeadlineCol>

        <HeadlineCol>
          <Panel>
            <h5>Total {incTax(countryCode)}</h5>
            <PrivateContent>
              <PanelStat
                text={`${data.total.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}`}
              />
            </PrivateContent>
          </Panel>
        </HeadlineCol>

        <HeadlineCol>
          <Panel>
            <h5>Total {tax(countryCode)}</h5>
            <PrivateContent>
              <PanelStat
                text={`${data.total_tax.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}`}
              />
            </PrivateContent>
          </Panel>
        </HeadlineCol>

        <HeadlineCol>
          <Panel>
            <h5>Count</h5>
            <PrivateContent>
              <PanelStat text={data.count} />
            </PrivateContent>
          </Panel>
        </HeadlineCol>
      </Row>

      <Row>
        <Col lg={6}>
          <ContactTable countryCode={countryCode} data={contacts} />
        </Col>

        <Col lg={6}>
          <AccountCodesTable countryCode={countryCode} data={accountCodes} />
        </Col>
      </Row>
    </>
  )
}

function HeadlineCol(props) {
  return (
    <Col lg={3} md={6} className='mb-4'>
      {props.children}
    </Col>
  )
}

function PanelStat({ text }) {
  const { fontSize, ref } = useFitText()

  return (
    <div style={{ fontSize: 30 }}>
      <div className='w-100' ref={ref} style={{ fontSize, height: 40 }}>
        {text}
      </div>
    </div>
  )
}

function ContactTable({ countryCode, data }) {
  const limit = 3

  const [showMore, setShowMore] = useState(false)

  const handleToggleShowMore = () => {
    setShowMore(!showMore)
  }

  return (
    <>
      <h5>Customers</h5>
      <Panel className='mb-3'>
        <PrivateContent>
          <Table responsive='md'>
            <thead>
              <tr>
                <th>Name</th>
                <th className='text-end'>
                  Total
                  <br />
                  <small>{exTax(countryCode)}</small>
                </th>
                <th className='text-end'>
                  Total
                  <br />
                  <small>{incTax(countryCode)}</small>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.slice(0, showMore ? data.length : limit).map(x => (
                <tr key={x.id}>
                  <td>{x.name}</td>
                  <td className='text-end'>
                    {x.sub_total.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </td>
                  <td className='text-end'>
                    {x.total.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </PrivateContent>
      </Panel>

      {data.length > limit && (
        <div className='d-flex justify-content-center'>
          <Button onClick={handleToggleShowMore} variant='outline-secondary' size='sm'>
            {showMore ? 'Show Less' : 'Show More'}
          </Button>
        </div>
      )}
    </>
  )
}

function AccountCodesTable({ countryCode, data }) {
  const limit = 3

  const [showMore, setShowMore] = useState(false)

  const handleToggleShowMore = () => {
    setShowMore(!showMore)
  }

  return (
    <>
      <h5>Account Codes</h5>

      <Panel className='mb-3'>
        <PrivateContent>
          <Table responsive='md'>
            <thead>
              <tr>
                <th>Name</th>
                <th className='text-end'>
                  Total
                  <br />
                  <small>{exTax(countryCode)}</small>
                </th>
                <th className='text-end'>
                  Total
                  <br />
                  <small>{incTax(countryCode)}</small>
                </th>
                <th className='text-end' style={{ minWidth: '70px' }}>
                  %
                  <br />
                  <small>{exTax(countryCode)}</small>
                </th>
                <th className='text-end'>
                  # of
                  <br />
                  Invoices
                </th>
              </tr>
            </thead>
            <tbody>
              {data.slice(0, showMore ? data.length : limit).map(x => (
                <tr key={x.id}>
                  <td>{x.account_code}</td>
                  <td className='text-end'>
                    {x.sub_total.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </td>
                  <td className='text-end'>
                    {x.total.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                  </td>
                  <td className='text-end'>
                    {x.sub_total_as_percentage.toLocaleString(undefined, {
                      minimumFractionDigits: 1,
                    })}
                    %
                  </td>
                  <td className='text-end'>{x.invoice_count}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </PrivateContent>
      </Panel>

      {data.length > limit && (
        <div className='d-flex justify-content-center'>
          <Button onClick={handleToggleShowMore} variant='outline-secondary' size='sm'>
            {showMore ? 'Show Less' : 'Show More'}
          </Button>
        </div>
      )}
    </>
  )
}

export default Monthly
