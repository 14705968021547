import { ResponsiveLine } from '@nivo/line'
import { getStats } from 'api/home'
import Error from 'components/Error'
import Loader from 'components/Loader'
import Panel from 'components/Panel'
import PrivateContent from 'components/PrivateContent'
import SubscriptionBanner from 'components/SubcriptionBanner'
import SyncNow from 'components/SyncNow'
import { ThemeContext } from 'contexts/ThemeContext'
import useWindowDimensions from 'hooks/useWindowDimensions'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  ArrowDownCircle,
  ArrowUpCircle,
  DashCircle,
  Download,
} from 'react-bootstrap-icons'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { Helmet } from 'react-helmet'
import { exTax, incTax } from 'terminology/invoices'
import useFitText from 'use-fit-text'
import styles from './Home.module.scss'

const initialStats = {
  success: null,
  body: {
    free_trial: '',
    subscribed: '',
    last_sync: '',
    can_resync: false,
    tenant_id: '',
    currency: '',
    country_code: '',
    connections: [],
    currencies: [],
    issued_invoices: {
      this_month: {
        total: '',
        total_tax: '',
        sub_total: '',
        sub_total_repeating: '',
        sub_total_percentage_repeating: '',
        repeating_to_be_raised: '',
      },
    },
    repeating_invoices: {
      total_invoice_count: '',
      invoice_limit: '',
      last_month: {
        count: '',
        total: '',
        total_tax: '',
        sub_total: '',
        sub_total_diff: '',
      },
      this_month: {
        count: '',
        total: '',
        total_tax: '',
        sub_total: '',
        sub_total_diff: '',
        contact_count: '',
        average_sub_total: '',
        average_total: '',
        average_sub_total_diff: '',
      },
      next_month: {
        count: '',
        total: '',
        total_tax: '',
        sub_total: '',
        sub_total_diff: '',
      },
      ended: {
        count: '',
        total: '',
        total_tax: '',
        sub_total: '',
      },
      ending_soon: {
        count: '',
        total: '',
        total_tax: '',
        sub_total: '',
      },
      contacts: {
        last_month: [],
        this_month: [],
        next_month: [],
        ended: [],
      },
      account_codes: {
        last_month: [],
        this_month: [],
        next_month: [],
        ended: [],
      },
    },
  },
}

function Home({ history, location }) {
  const [error, setError] = useState(false)
  const [syncing, setSyncing] = useState(false)
  const [tenantId, setTenantId] = useState('')
  const [currency, setCurrency] = useState('')
  const [stats, setStats] = useState(initialStats)

  const handleSync = useCallback(async () => {
    setSyncing(true)
    const res = await getStats({ tenant_id: tenantId, currency: currency, sync: 'yes' })

    if (!res.success) {
      setError(true)
      history.push({ pathname: '/' })
    } else {
      setSyncing(false)
      setStats(res)
    }
  }, [tenantId, currency, setError, history])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const _tenantId = queryParams.get('tenantId') ?? tenantId
    const _currency = currency
    setTenantId(_tenantId)

    setStats(initialStats)
    getStats({ tenant_id: _tenantId, currency: _currency }).then(x => {
      if (!x.success) {
        setError(true)
      } else {
        setStats(x)
        if (x.body.should_resync) {
          handleSync()
        }
      }
    })
  }, [tenantId, currency, history, handleSync, location.search])

  const handleChangeTenant = e => {
    history.push({ pathname: '', search: `tenantId=${e.target.value}` })
  }

  const handleChangeCurrency = e => {
    const val = e.target.value
    setCurrency(val)
  }

  const planLimitReached = () => {
    if (stats.body?.repeating_invoices ?? false) {
      return (
        stats.body.repeating_invoices.invoice_limit !== null &&
        stats.body.repeating_invoices.total_invoice_count >
          stats.body.repeating_invoices.invoice_limit
      )
    }

    return false
  }

  if (error) return <Error />

  if (stats.success === null) return <Loader />

  return (
    <>
      <SubscriptionBanner
        subscribed={stats.body.subscribed}
        freeTrial={stats.body.free_trial}
        planLimitReached={planLimitReached()}
      />

      <Container>
        <Helmet>
          <title>Moolah - Home</title>
        </Helmet>
        <div className='d-flex align-items-center'>
          <h1 className='mb-3'>Repeating Invoice Overview</h1>
          {stats.body.repeating_invoices && (
            <div className='ms-3 mb-2'>
              <a
                className='btn btn-sm btn-outline-primary'
                target='_blank'
                rel='noreferrer'
                href={`/download/overview/${
                  tenantId ? tenantId : stats.body.connections[0].tenant_id
                }/${currency ? currency : stats.body.currencies[0].code}`}
                alt='Download'>
                <Download /> Download
              </a>
            </div>
          )}
        </div>
        <SyncNow
          lastSync={stats.body.last_sync}
          showSyncButton={stats.body.can_resync}
          doSync={handleSync}
          defaultSyncing={syncing}
        />
        {!stats.body.repeating_invoices ? (
          <Loader />
        ) : (
          <>
            <Row>
              <Col sm={6} className='mb-3'>
                <Form.Select
                  className='mr-4'
                  disabled={stats.body.connections.length <= 1}
                  value={stats.body.tenant_id}
                  onChange={handleChangeTenant}>
                  {stats.body.connections.map(x => (
                    <option key={x.tenant_id} value={x.tenant_id}>
                      {x.tenant_name} Invoices
                    </option>
                  ))}
                </Form.Select>
              </Col>

              <Col sm={6} className='mb-3'>
                <Form.Select
                  className='ml-4'
                  disabled={stats.body.currencies.length <= 1}
                  value={stats.body.currency}
                  onChange={handleChangeCurrency}>
                  {stats.body.currencies.map(x => (
                    <option key={x.code} value={x.code}>
                      {x.code} Invoices
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>

            <Row>
              <HeadlineCol>
                <Panel className='h-100'>
                  <h5>Last Month</h5>
                  <PrivateContent>
                    <PanelStat
                      text={`${stats.body.repeating_invoices.last_month.sub_total.toLocaleString(
                        undefined,
                        { minimumFractionDigits: 2 }
                      )}`}
                    />

                    <div className='mt-1'>
                      {stats.body.repeating_invoices.last_month.total.toLocaleString(
                        undefined,
                        { minimumFractionDigits: 2 }
                      )}{' '}
                      {incTax(stats.body.country_code)}
                    </div>
                    <PercentageChange
                      pc={stats.body.repeating_invoices.last_month.sub_total_diff}
                    />
                  </PrivateContent>
                </Panel>
              </HeadlineCol>

              <HeadlineCol>
                <Panel className='h-100'>
                  <h5>This Month</h5>
                  <PrivateContent>
                    <PanelStat
                      text={`${stats.body.repeating_invoices.this_month.sub_total.toLocaleString(
                        undefined,
                        { minimumFractionDigits: 2 }
                      )}`}
                    />

                    <div className='mt-1'>
                      {stats.body.repeating_invoices.this_month.total.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                        }
                      )}{' '}
                      {incTax(stats.body.country_code)}
                    </div>
                    <PercentageChange
                      pc={stats.body.repeating_invoices.this_month.sub_total_diff}
                    />
                  </PrivateContent>
                </Panel>
              </HeadlineCol>

              <HeadlineCol>
                <Panel className='h-100'>
                  <h5>Next Month</h5>
                  <PrivateContent>
                    <PanelStat
                      text={`${stats.body.repeating_invoices.next_month.sub_total.toLocaleString(
                        undefined,
                        { minimumFractionDigits: 2 }
                      )}`}
                    />

                    <div className='mt-1'>
                      {stats.body.repeating_invoices.next_month.total.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                        }
                      )}{' '}
                      {incTax(stats.body.country_code)}
                    </div>
                    <PercentageChange
                      pc={stats.body.repeating_invoices.next_month.sub_total_diff}
                    />
                  </PrivateContent>
                </Panel>
              </HeadlineCol>

              <HeadlineCol>
                <Panel className='h-100'>
                  <h5>Total Repeating Invoices</h5>
                  <PrivateContent>
                    <PanelStat text={stats.body.repeating_invoices.total_invoice_count} />

                    {stats.body.repeating_invoices.invoice_limit !== null &&
                      stats.body.repeating_invoices.total_invoice_count >
                        stats.body.repeating_invoices.invoice_limit && (
                        <div className='mt-1'>
                          <small>
                            Reporting on {stats.body.repeating_invoices.invoice_limit}{' '}
                            invoices - Upgrade your plan to report on all invoices.
                          </small>
                        </div>
                      )}
                  </PrivateContent>
                </Panel>
              </HeadlineCol>
            </Row>

            <Row>
              <Col xl={9}>
                <PrivateContent>
                  <div style={{ height: '440px' }}>
                    <InvoiceChart
                      countryCode={stats.body.country_code}
                      data={stats.body.repeating_invoices.chart}
                    />
                  </div>
                </PrivateContent>
              </Col>

              <Col xl={3}>
                <Row>
                  <HeadlineCol lg={12} md={6}>
                    <Panel className='h-100'>
                      <h5>Raised Invoice Value</h5>
                      <PrivateContent>
                        <PanelStat
                          text={`${stats.body.issued_invoices.this_month.sub_total.toLocaleString(
                            undefined,
                            { minimumFractionDigits: 2 }
                          )}`}
                        />

                        <div className='mt-1'>
                          {stats.body.issued_invoices.this_month.total.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                            }
                          )}{' '}
                          {incTax(stats.body.country_code)}
                        </div>

                        <div className='mt-2'>
                          Value of raised invoices so far this month
                        </div>
                      </PrivateContent>
                    </Panel>
                  </HeadlineCol>

                  <HeadlineCol lg={12} md={6}>
                    <Panel className='h-100'>
                      <h5>To Be Raised Value</h5>
                      <PrivateContent>
                        <PanelStat
                          text={`${stats.body.issued_invoices.this_month.repeating_to_be_raised.toLocaleString(
                            undefined,
                            { minimumFractionDigits: 2 }
                          )}`}
                        />

                        <div className='mt-2'>
                          Value of repeating invoices still to be raised this month
                        </div>
                      </PrivateContent>
                    </Panel>
                  </HeadlineCol>
                </Row>
              </Col>
            </Row>

            <Row>
              <HeadlineCol>
                <Panel className='h-100'>
                  <h5>% of Repeating</h5>
                  <PrivateContent>
                    <PanelStat
                      text={`${stats.body.issued_invoices.this_month.sub_total_percentage_repeating}%`}
                    />

                    <div className='mt-2'>
                      {stats.body.issued_invoices.this_month.sub_total_repeating.toLocaleString(
                        undefined,
                        { minimumFractionDigits: 2 }
                      )}{' '}
                      of raised invoices is from repeating invoices
                    </div>
                  </PrivateContent>
                </Panel>
              </HeadlineCol>

              <HeadlineCol>
                <Panel className='h-100'>
                  <h5>Recurring Customers</h5>
                  <PrivateContent>
                    <PanelStat
                      text={`${stats.body.repeating_invoices.this_month.contact_count}`}
                    />

                    <div className='mt-1'>
                      Number of customers with repeating invoices
                    </div>
                  </PrivateContent>
                </Panel>
              </HeadlineCol>

              <HeadlineCol>
                <Panel className='h-100'>
                  <h5>Average Invoice Value</h5>
                  <PrivateContent>
                    <PanelStat
                      text={`${stats.body.repeating_invoices.this_month.average_sub_total.toLocaleString(
                        undefined,
                        { minimumFractionDigits: 2 }
                      )}`}
                    />

                    <div className='mt-1'>
                      {stats.body.repeating_invoices.this_month.average_total.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                        }
                      )}{' '}
                      {incTax(stats.body.country_code)}
                    </div>
                    <PercentageChange
                      pc={stats.body.repeating_invoices.this_month.average_sub_total_diff}
                    />
                  </PrivateContent>
                </Panel>
              </HeadlineCol>

              <HeadlineCol>
                <Panel className='h-100'>
                  <h5>Ending Soon Value</h5>
                  <PrivateContent>
                    <PanelStat
                      text={`${stats.body.repeating_invoices.ending_soon.sub_total.toLocaleString(
                        undefined,
                        { minimumFractionDigits: 2 }
                      )}`}
                    />

                    <div className='mt-1'>
                      <strong>{stats.body.repeating_invoices.ending_soon.count}</strong>{' '}
                      Repeating invoices ending within the next 3 months
                    </div>
                  </PrivateContent>
                </Panel>
              </HeadlineCol>
            </Row>
          </>
        )}
      </Container>
    </>
  )
}

function HeadlineCol({ lg, md, children }) {
  return (
    <Col lg={lg ? lg : 3} md={md ? md : 6} className='mb-4'>
      {children}
    </Col>
  )
}

function PanelStat({ text }) {
  const { fontSize, ref } = useFitText()

  return (
    <div style={{ fontSize: 30 }}>
      <div className='w-100' ref={ref} style={{ fontSize, height: 40 }}>
        {text}
      </div>
    </div>
  )
}

function PercentageChange({ pc }) {
  let icon = <DashCircle />
  let className = 'text-secondary'

  if (pc > 0) {
    icon = <ArrowUpCircle />
    className = 'text-success'
  } else if (pc < 0) {
    icon = <ArrowDownCircle />
    className = 'text-danger'
  }

  return (
    <div className={className}>
      <strong>
        <small>
          <span className='me-1'>{icon}</span>
          {pc}%
        </small>
      </strong>
    </div>
  )
}

function InvoiceChart({ countryCode, data }) {
  const { width } = useWindowDimensions()
  const { theme } = useContext(ThemeContext)
  const [forecastPercent, setForecastPercent] = useState(3)
  const [chartData, setChartData] = useState()

  useEffect(() => {
    const calculateForecast = () => {
      if (!forecastPercent) return []

      const forecastGrowth = forecastPercent / 100 + 1
      const forecast = []
      let forecastValue

      for (const [i, v] of data.entries()) {
        if (i === 0 || !v.future) {
          forecastValue = v.sub_total
        } else {
          const diff = v.sub_total - data[i - 1].sub_total
          forecastValue = (forecastValue + diff) * forecastGrowth
        }

        forecast.push({ long_label: v.long_label, x: v.label, y: forecastValue })
      }

      return forecast
    }

    setChartData([
      {
        id: `Total (${exTax(countryCode)})`,
        data: data.map(x => ({ long_label: x.long_label, x: x.label, y: x.sub_total })),
      },
      /*{
        id: `Total (${incTax(countryCode)})`,
        data: data.map(x => ({ x: x.label, y: x.total })),
      },*/
      {
        id: `Forecast (${exTax(countryCode)})`,
        data: calculateForecast(),
      },
    ])
  }, [data, forecastPercent, countryCode])

  const handleChangeGrowth = event => {
    const sanitizedValue = event.target.value.replace(/\D+/g, '')
    if (sanitizedValue && sanitizedValue > 100) {
      setForecastPercent(100)
    } else if (sanitizedValue && sanitizedValue <= 0) {
      setForecastPercent()
    } else {
      setForecastPercent(sanitizedValue)
    }
  }

  return (
    <>
      <Row>
        <Col xl={12}>
          <div className='d-flex align-items-center'>
            <Form.Label className='w-1o0 mt-1 me-2'>
              Forecasted monthly growth:
            </Form.Label>
            <Form.Control
              onChange={handleChangeGrowth}
              value={forecastPercent}
              className='me-2'
              style={{ width: '50px' }}
              size='sm'
            />
            %
          </div>
        </Col>
      </Row>
      <ResponsiveLine
        data={chartData}
        colors={['#989fd9', /*'#6773d6',*/ '#29a329']}
        margin={{ top: 20, right: 0, bottom: 90, left: 60 }}
        tooltip={props => (
          <div className={styles.tooltip}>
            <strong style={{ color: theme === 'light' ? 'black' : 'white' }}>
              {props.point.serieId}
              <br />
              {props.point.data.y.toLocaleString(undefined, { minimumFractionDigits: 2 })}
              <br />
              {props.point.data.long_label}
            </strong>
          </div>
        )}
        theme={{
          textColor: theme === 'dark' ? 'white' : 'black',
          grid: {
            line: {
              stroke: theme === 'dark' ? '#474747' : '#b3b3b3',
              strokeWidth: 1,
            },
          },
        }}
        curve='monotoneX'
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: '0', max: 'auto', stacked: false, reverse: false }}
        pointSize={10}
        axisBottom={width > 900 ? {} : null}
        enablePoints={false}
        lineWidth={4}
        enableArea={true}
        areaOpacity={0.1}
        areaBlendMode='lighten'
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
      />
    </>
  )
}

export default Home
