import icon from 'assets/loader.png'
import React from 'react'

function RelativeLoader() {
  return (
    <div className='pt-4 h-100 d-flex align-items-center justify-content-center'>
      <img src={icon} alt='loading' />
    </div>
  )
}

export default RelativeLoader
