import PropTypes from 'prop-types'
import React from 'react'
import styles from './Panel.module.scss'

function Panel({ className, children }) {
  return <div className={`${styles.panel} ${className}`}>{children}</div>
}

Panel.propTypes = {
  className: PropTypes.string,
}

Panel.defaultProps = {
  className: '',
}

export default Panel
