import xeroButton from 'assets/xero/connect-blue.svg'
import React from 'react'

function Error() {
  return (
    <div className='mt-5 text-center'>
      <div>
        <p>
          There was an error requesting your Xero data. Please re-connect your account to
          try again.
        </p>

        <div className='mt-5'>
          <a href='/xero/auth/authorize'>
            <img src={xeroButton} alt='connect' />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Error
