import AuthenticatedRoute from 'components/routes/AuthenticatedRoute'
import ReportRoute from 'components/routes/ReportRoute'
import UnauthenticatedRoute from 'components/routes/UnauthenticatedRoute'
import Home from 'containers/home/Home'
import Login from 'containers/login/Login'
import Monthly from 'containers/monthly/Monthly'
import MyAccount from 'containers/my-account/MyAccount'
import NotFound from 'containers/not-found/NotFound'
import ReportsHome from 'containers/reports/home/Home'
import { Route, Switch } from 'react-router-dom'

const Routes = () => {
  return (
    <Switch>
      <AuthenticatedRoute path='/' exact component={Home} />
      <AuthenticatedRoute path='/monthly' exact component={Monthly} />

      <ReportRoute path='/reports/overview/:id' exact component={ReportsHome} />

      <AuthenticatedRoute path='/my-account' exact component={MyAccount} />
      <UnauthenticatedRoute path='/login' exact component={Login} />

      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
