import { ResponsiveLine } from '@nivo/line'
import { getStats } from 'api/reports'
import Error from 'components/Error'
import Loader from 'components/Loader'
import Panel from 'components/Panel'
import { ThemeContext } from 'contexts/ThemeContext'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { useContext, useEffect, useState } from 'react'
import { ArrowDownCircle, ArrowUpCircle, DashCircle } from 'react-bootstrap-icons'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { useParams } from 'react-router-dom'
import { exTax, incTax } from 'terminology/invoices'
import useFitText from 'use-fit-text'
import styles from './Home.module.scss'

const initialStats = {
  success: null,
  body: {
    tenant_id: '',
    currency: '',
    country_code: '',
    connections: [],
    currencies: [],
    issued_invoices: {
      this_month: {
        total: '',
        total_tax: '',
        sub_total: '',
        sub_total_repeating: '',
        sub_total_percentage_repeating: '',
        repeating_to_be_raised: '',
      },
    },
    repeating_invoices: {
      total_invoice_count: '',
      invoice_limit: '',
      last_month: {
        count: '',
        total: '',
        total_tax: '',
        sub_total: '',
        sub_total_diff: '',
      },
      this_month: {
        count: '',
        total: '',
        total_tax: '',
        sub_total: '',
        sub_total_diff: '',
        contact_count: '',
        average_sub_total: '',
        average_total: '',
        average_sub_total_diff: '',
      },
      next_month: {
        count: '',
        total: '',
        total_tax: '',
        sub_total: '',
        sub_total_diff: '',
      },
      ended: {
        count: '',
        total: '',
        total_tax: '',
        sub_total: '',
      },
      ending_soon: {
        count: '',
        total: '',
        total_tax: '',
        sub_total: '',
      },
      contacts: {
        last_month: [],
        this_month: [],
        next_month: [],
        ended: [],
      },
      account_codes: {
        last_month: [],
        this_month: [],
        next_month: [],
        ended: [],
      },
    },
  },
}

function Home({ history, location }) {
  const { id } = useParams()
  const [error, setError] = useState(false)
  const [tenantId, setTenantId] = useState('')
  const [currency, setCurrency] = useState('')
  const [stats, setStats] = useState(initialStats)

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const _tenantId = queryParams.get('tenantId') ?? tenantId
    const _currency = queryParams.get('currency') ?? currency
    setTenantId(_tenantId)
    setCurrency(_currency)

    setStats(initialStats)
    getStats(id, { tenant_id: _tenantId, currency: _currency }).then(x => {
      if (!x.success) {
        setError(true)
      } else {
        setStats(x)
      }
    })
  }, [id, tenantId, currency, history, location.search])

  const handleChangeTenant = e => {
    history.push({ pathname: '', search: `tenantId=${e.target.value}` })
  }

  const handleChangeCurrency = e => {
    const val = e.target.value
    setCurrency(val)
  }

  if (error) return <Error />

  if (stats.success === null) return <Loader />

  return (
    <>
      <Container fluid className='p-5'>
        <h1 className='mb-3'>Repeating Invoice Overview</h1>
        {!stats.body.repeating_invoices ? (
          <Loader />
        ) : (
          <>
            <Row>
              <Col xs={6} className='mb-3'>
                <Form.Select
                  className='mr-4'
                  disabled={stats.body.connections.length <= 1}
                  value={stats.body.tenant_id}
                  onChange={handleChangeTenant}>
                  {stats.body.connections.map(x => (
                    <option key={x.tenant_id} value={x.tenant_id}>
                      {x.tenant_name} Invoices
                    </option>
                  ))}
                </Form.Select>
              </Col>

              <Col xs={6} className='mb-3'>
                <Form.Select
                  className='ml-4'
                  disabled={stats.body.currencies.length <= 1}
                  value={stats.body.currency}
                  onChange={handleChangeCurrency}>
                  {stats.body.currencies.map(x => (
                    <option key={x.code} value={x.code}>
                      {x.code} Invoices
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>

            <Row>
              <HeadlineCol>
                <Panel className='h-100'>
                  <h5>Last Month</h5>
                  <PanelStat
                    text={`${stats.body.repeating_invoices.last_month.sub_total.toLocaleString(
                      undefined,
                      { minimumFractionDigits: 2 }
                    )}`}
                  />

                  <div className='mt-1'>
                    {stats.body.repeating_invoices.last_month.total.toLocaleString(
                      undefined,
                      { minimumFractionDigits: 2 }
                    )}{' '}
                    {incTax(stats.body.country_code)}
                  </div>
                  <PercentageChange
                    pc={stats.body.repeating_invoices.last_month.sub_total_diff}
                  />
                </Panel>
              </HeadlineCol>

              <HeadlineCol>
                <Panel className='h-100'>
                  <h5>This Month</h5>
                  <PanelStat
                    text={`${stats.body.repeating_invoices.this_month.sub_total.toLocaleString(
                      undefined,
                      { minimumFractionDigits: 2 }
                    )}`}
                  />

                  <div className='mt-1'>
                    {stats.body.repeating_invoices.this_month.total.toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                      }
                    )}{' '}
                    {incTax(stats.body.country_code)}
                  </div>
                  <PercentageChange
                    pc={stats.body.repeating_invoices.this_month.sub_total_diff}
                  />
                </Panel>
              </HeadlineCol>

              <HeadlineCol>
                <Panel className='h-100'>
                  <h5>Next Month</h5>
                  <PanelStat
                    text={`${stats.body.repeating_invoices.next_month.sub_total.toLocaleString(
                      undefined,
                      { minimumFractionDigits: 2 }
                    )}`}
                  />

                  <div className='mt-1'>
                    {stats.body.repeating_invoices.next_month.total.toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                      }
                    )}{' '}
                    {incTax(stats.body.country_code)}
                  </div>
                  <PercentageChange
                    pc={stats.body.repeating_invoices.next_month.sub_total_diff}
                  />
                </Panel>
              </HeadlineCol>

              <HeadlineCol>
                <Panel className='h-100'>
                  <h5>Total Repeating Invoices</h5>
                  <PanelStat text={stats.body.repeating_invoices.total_invoice_count} />

                  {stats.body.repeating_invoices.invoice_limit !== null &&
                    stats.body.repeating_invoices.total_invoice_count >
                      stats.body.repeating_invoices.invoice_limit && (
                      <div className='mt-1'>
                        <small>
                          Reporting on {stats.body.repeating_invoices.invoice_limit}{' '}
                          invoices - Upgrade your plan to report on all invoices.
                        </small>
                      </div>
                    )}
                </Panel>
              </HeadlineCol>
            </Row>

            <Row>
              <Col xs={12}>
                <div style={{ height: '440px' }}>
                  <InvoiceChart
                    countryCode={stats.body.country_code}
                    data={stats.body.repeating_invoices.chart}
                  />
                </div>
              </Col>

              <Col xl={12}>
                <Row>
                  <HeadlineCol md={6}>
                    <Panel className='h-100'>
                      <h5>Raised Invoice Value</h5>
                      <PanelStat
                        text={`${stats.body.issued_invoices.this_month.sub_total.toLocaleString(
                          undefined,
                          { minimumFractionDigits: 2 }
                        )}`}
                      />

                      <div className='mt-1'>
                        {stats.body.issued_invoices.this_month.total.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                          }
                        )}{' '}
                        {incTax(stats.body.country_code)}
                      </div>

                      <div className='mt-2'>
                        Value of raised invoices so far this month
                      </div>
                    </Panel>
                  </HeadlineCol>

                  <HeadlineCol md={6}>
                    <Panel className='h-100'>
                      <h5>To Be Raised Value</h5>
                      <PanelStat
                        text={`${stats.body.issued_invoices.this_month.repeating_to_be_raised.toLocaleString(
                          undefined,
                          { minimumFractionDigits: 2 }
                        )}`}
                      />

                      <div className='mt-2'>
                        Value of repeating invoices still to be raised this month
                      </div>
                    </Panel>
                  </HeadlineCol>
                </Row>
              </Col>
            </Row>

            <Row>
              <HeadlineCol>
                <Panel className='h-100'>
                  <h5>% of Repeating</h5>
                  <PanelStat
                    text={`${stats.body.issued_invoices.this_month.sub_total_percentage_repeating}%`}
                  />

                  <div className='mt-2'>
                    {stats.body.issued_invoices.this_month.sub_total_repeating.toLocaleString(
                      undefined,
                      { minimumFractionDigits: 2 }
                    )}{' '}
                    of raised invoices is from repeating invoices
                  </div>
                </Panel>
              </HeadlineCol>

              <HeadlineCol>
                <Panel className='h-100'>
                  <h5>Recurring Customers</h5>
                  <PanelStat
                    text={`${stats.body.repeating_invoices.this_month.contact_count}`}
                  />

                  <div className='mt-1'>Number of customers with repeating invoices</div>
                </Panel>
              </HeadlineCol>

              <HeadlineCol>
                <Panel className='h-100'>
                  <h5>Average Invoice Value</h5>
                  <PanelStat
                    text={`${stats.body.repeating_invoices.this_month.average_sub_total.toLocaleString(
                      undefined,
                      { minimumFractionDigits: 2 }
                    )}`}
                  />

                  <div className='mt-1'>
                    {stats.body.repeating_invoices.this_month.average_total.toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                      }
                    )}{' '}
                    {incTax(stats.body.country_code)}
                  </div>
                  <PercentageChange
                    pc={stats.body.repeating_invoices.this_month.average_sub_total_diff}
                  />
                </Panel>
              </HeadlineCol>

              <HeadlineCol>
                <Panel className='h-100'>
                  <h5>Ending Soon Value</h5>
                  <PanelStat
                    text={`${stats.body.repeating_invoices.ending_soon.sub_total.toLocaleString(
                      undefined,
                      { minimumFractionDigits: 2 }
                    )}`}
                  />

                  <div className='mt-1'>
                    <strong>{stats.body.repeating_invoices.ending_soon.count}</strong>{' '}
                    Repeating invoices ending within the next 3 months
                  </div>
                </Panel>
              </HeadlineCol>
            </Row>
          </>
        )}
      </Container>
    </>
  )
}

function HeadlineCol({ lg, md, children }) {
  return (
    <Col lg={lg ? lg : 6} md={md ? md : 6} className='mb-4'>
      {children}
    </Col>
  )
}

function PanelStat({ text }) {
  const { fontSize, ref } = useFitText()

  return (
    <div style={{ fontSize: 30 }}>
      <div className='w-100' ref={ref} style={{ fontSize, height: 40 }}>
        {text}
      </div>
    </div>
  )
}

function PercentageChange({ pc }) {
  let icon = <DashCircle />
  let className = 'text-secondary'

  if (pc > 0) {
    icon = <ArrowUpCircle />
    className = 'text-success'
  } else if (pc < 0) {
    icon = <ArrowDownCircle />
    className = 'text-danger'
  }

  return (
    <div className={className}>
      <strong>
        <small>
          <span className='me-1'>{icon}</span>
          {pc}%
        </small>
      </strong>
    </div>
  )
}

function InvoiceChart({ countryCode, data }) {
  const { width } = useWindowDimensions()
  const { theme } = useContext(ThemeContext)
  const [chartData, setChartData] = useState()

  useEffect(() => {
    setChartData([
      {
        id: `Total (${exTax(countryCode)})`,
        data: data.map(x => ({ long_label: x.long_label, x: x.label, y: x.sub_total })),
      },
    ])
  }, [data, countryCode])

  return (
    <>
      <ResponsiveLine
        data={chartData}
        colors={['#989fd9']}
        margin={{ top: 20, right: 20, bottom: 90, left: 60 }}
        tooltip={props => (
          <div className={styles.tooltip}>
            <strong style={{ color: theme === 'light' ? 'black' : 'white' }}>
              {props.point.serieId}
              <br />
              {props.point.data.y.toLocaleString(undefined, { minimumFractionDigits: 2 })}
              <br />
              {props.point.data.long_label}
            </strong>
          </div>
        )}
        theme={{
          textColor: theme === 'dark' ? 'white' : 'black',
          grid: {
            line: {
              stroke: theme === 'dark' ? '#474747' : '#b3b3b3',
              strokeWidth: 1,
            },
          },
        }}
        curve='monotoneX'
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: '0', max: 'auto', stacked: false, reverse: false }}
        pointSize={10}
        axisBottom={width > 900 ? {} : null}
        enablePoints={false}
        lineWidth={4}
        enableArea={true}
        areaOpacity={0.1}
        areaBlendMode='lighten'
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
      />
    </>
  )
}

export default Home
