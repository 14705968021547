export function tax(countryCode) {
  return countryCode === 'GB' ? 'VAT' : 'Tax'
}

export function incTax(countryCode) {
  return countryCode === 'GB' ? 'inc VAT' : 'inc Tax'
}

export function exTax(countryCode) {
  return countryCode === 'GB' ? 'ex-VAT' : 'ex Tax'
}
