import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import config from 'config'
import React from 'react'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import App from './App'
import AuthContext from './contexts/AuthContext'
import PrivateModeContext from './contexts/PrivateModeContext'
import ThemeContext from './contexts/ThemeContext'
import './index.scss'
import reportWebVitals from './reportWebVitals'

if (config.SENTRY_DSN) {
  Sentry.init({
    environment: config.env,
    dsn: config.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampler: samplingContext => {
      return 0.2
    },
  })
}

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <meta charSet='utf-8' />
      <title>Moolah</title>
    </Helmet>

    <Router>
      <ThemeContext>
        <PrivateModeContext>
          <AuthContext>
            <Sentry.ErrorBoundary fallback={'An error has occurred'}>
              <ToastProvider
                //components={{ Toast: bsAlertToast }}
                autoDismiss={true}
                placement='bottom-center'
              >
                <App />
              </ToastProvider>
            </Sentry.ErrorBoundary>
          </AuthContext>
        </PrivateModeContext>
      </ThemeContext>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
