import { PrivateModeContext } from 'contexts/PrivateModeContext'
import React, { useContext, useEffect, useState } from 'react'

function PrivateContent({ children }) {
  const { privateModeEnabled } = useContext(PrivateModeContext)
  const [hidden, setHidden] = useState(privateModeEnabled)

  useEffect(() => {
    setHidden(privateModeEnabled)
  }, [privateModeEnabled])

  return (
    <div
      style={{ position: 'relative' }}
      className='h-100'
      onMouseEnter={() => {
        privateModeEnabled && setHidden(false)
      }}
      onMouseLeave={() => {
        privateModeEnabled && setHidden(true)
      }}
    >
      <div
        style={{
          position: 'relative',
          opacity: hidden ? 0 : 1,
          transition: 'all 0.2s',
          zIndex: 2,
        }}
      >
        {children}
      </div>
      <div
        style={{
          position: 'absolute',
          zIndex: 1,
          width: '100%',
          height: '50%',
          top: 0,
          left: 0,
          opacity: hidden ? 1 : 0,
          transition: 'all 0.2s',
        }}
      >
        <div className='h-100 d-flex align-items-center justify-content-center text-muted'>
          Hover to reveal
        </div>
      </div>
    </div>
  )
}

export default PrivateContent
