import config from 'config'
import React from 'react'
import { Alert, Container } from 'react-bootstrap'

function SubscriptionBanner({ subscribed, freeTrial, planLimitReached }) {
  if (subscribed && planLimitReached) {
    return (
      <Alert variant='primary' className='mt-2'>
        <Container className='mt-1'>
          Your organisation has more invoices than your plan limit reports on.{' '}
          <a
            style={{ color: 'white' }}
            href={config.XERO_PRICING_PLAN_URL}
            target='_blank'
            rel='noopener noreferrer'
          >
            Upgrade your plan to report on all invoices.
          </a>
        </Container>
      </Alert>
    )
  } else if (!subscribed && freeTrial) {
    return (
      <Alert variant='primary' className='mt-2'>
        <Container className='mt-1'>
          You are currently getting all premium features for free! Your trial ends on{' '}
          {freeTrial}.{' '}
          <a
            style={{ color: 'white' }}
            href={config.XERO_PRICING_PLAN_URL}
            target='_blank'
            rel='noopener noreferrer'
          >
            Upgrade your organisation's Moolah plan with Xero.
          </a>
        </Container>
      </Alert>
    )
  } else if (!freeTrial && !subscribed) {
    return (
      <Alert variant='primary' className='mt-2'>
        <Container>
          You currently have limited access and reports are generated using a limited
          number of invoices.{' '}
          <a
            style={{ color: 'white' }}
            href={config.XERO_PRICING_PLAN_URL}
            target='_blank'
            rel='noopener noreferrer'
          >
            Upgrade your organisation's Moolah plan with Xero.
          </a>
        </Container>
      </Alert>
    )
  } else {
    return null
  }
}

export default SubscriptionBanner
