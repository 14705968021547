import Unauth from 'containers/layouts/Unauth'
import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AuthContext } from './../../contexts/AuthContext'

function UnauthenticatedRoute({ component: C, ...routeProps }) {
  const { authenticating, authenticated } = useContext(AuthContext)
  return (
    !authenticating && (
      <Route
        {...routeProps}
        render={props =>
          !authenticated ? <Unauth component={C} {...props} /> : <Redirect to='/' />
        }
      />
    )
  )
}

export default UnauthenticatedRoute
