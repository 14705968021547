import icon from 'assets/loader.png'
import React from 'react'

function Loader() {
  return (
    <div
      style={{ position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh' }}
    >
      <div className='h-100 d-flex align-items-center justify-content-center'>
        <img src={icon} alt='loading' />
      </div>
    </div>
  )
}

export default Loader
