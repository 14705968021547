import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'

function SyncNow({ lastSync, showSyncButton, doSync, defaultSyncing }) {
  const [syncing, setSyncing] = useState(defaultSyncing)
  const [synced, setSynced] = useState(false)

  useEffect(() => {
    setSyncing(defaultSyncing)
  }, [defaultSyncing])

  const handleSync = async () => {
    setSyncing(true)
    await doSync()
    setSyncing(false)
    setSynced(true)
  }

  const buttonContent = () => {
    if (syncing) {
      return (
        <div className='d-flex align-items-center'>
          <Spinner size='sm' variant='primary' animation='grow' />
          <span className='ms-2'>Syncing</span>
        </div>
      )
    } else if (synced) {
      return 'Synced'
    } else {
      return 'Sync Now'
    }
  }

  return (
    <div className='mb-3'>
      Last sync: {lastSync}
      {showSyncButton && (
        <Button
          className='ms-3'
          variant='outline-primary'
          size='sm'
          onClick={handleSync}
          disabled={syncing || synced}
        >
          {buttonContent()}
        </Button>
      )}
    </div>
  )
}

SyncNow.propTypes = {
  lastSync: PropTypes.string,
  showSyncButton: PropTypes.bool,
  doSync: PropTypes.func,
  defaultSyncing: PropTypes.bool,
}

SyncNow.defaultProps = {
  showSyncButton: true,
  defaultSyncing: false,
}

export default SyncNow
