import config from 'config'
import { getJsonHeaders } from './helpers'

export async function me() {
  try {
    const result = await fetch(config.API_URL + '/iapi/me', {
      headers: await getJsonHeaders(),
    })

    if (result.status !== 200) {
      return false
    }

    return await result.json()
  } catch (e) {
    return false
  }
}

export async function logout() {
  try {
    const res = await fetch(config.API_URL + '/iapi/logout', {
      method: 'POST',
      headers: await getJsonHeaders(),
    })

    if (res.status === 204) {
      return true
    }

    return false
  } catch (e) {
    return false
  }
}

export async function getXeroConnections() {
  const result = await fetch(config.API_URL + '/iapi/xero/connections', {
    headers: await getJsonHeaders(),
  })

  return {
    success: result.status === 200,
    status: result.status,
    body: await result.json(),
  }
}

export async function deleteXeroConnection(id) {
  const result = await fetch(config.API_URL + `/iapi/xero/connections/${id}`, {
    method: 'DELETE',
    headers: await getJsonHeaders(),
    body: JSON.stringify({}),
  })

  return result.status === 204
}

export async function disconnectXero() {
  const res = await fetch(config.API_URL + '/iapi/xero/disconnect', {
    method: 'DELETE',
    headers: await getJsonHeaders(),
  })

  return res.status === 200
}
