import connectedAppImg from 'assets/xero/connected-app.png'
import xeroDeveloper from 'assets/xero/xero-developer.png'
import ThemeToggle from 'components/ThemeToggle'
import { AuthContext } from 'contexts/AuthContext'
import { PrivateModeContext } from 'contexts/PrivateModeContext'
import { ThemeContext } from 'contexts/ThemeContext'
import React, { useContext } from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import Switch from 'react-switch'
import styles from './Auth.module.scss'

function Layout({ component: C, ...props }) {
  const { handleLogout } = useContext(AuthContext)
  const { topbarBg } = useContext(ThemeContext)
  const { privateModeEnabled, togglePrivateModeEnabled } = useContext(PrivateModeContext)
  const { search } = useLocation()

  return (
    <>
      <Navbar expand='lg' bg={topbarBg} variant='dark' className={styles.navbar}>
        <Container>
          <Navbar.Brand style={{ color: 'white' }}>
            <img src='/logo-light.svg' alt='Logo' className='me-3' height={34} />
            <ThemeToggle />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse id='navbar'>
            <Nav className={`${styles.items} ms-auto`}>
              <div
                className='h-100 d-flex align-items-center me-3'
                style={{ paddingTop: '14px', fontSize: '14px' }}
              >
                <span>Private Mode</span>
                <Switch
                  className='ps-2'
                  onChange={togglePrivateModeEnabled}
                  checked={privateModeEnabled}
                  handleDiameter={22}
                  height={16}
                  width={36}
                  uncheckedIcon={null}
                  checkedIcon={null}
                />
              </div>

              <Nav.Link as={NavLink} to={`/${search}`} exact>
                Overview
              </Nav.Link>

              <Nav.Link as={NavLink} to={`/monthly${search}`} exact>
                Monthly
              </Nav.Link>

              <Nav.Link as={NavLink} to='/my-account' exact>
                My Account
              </Nav.Link>

              <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className={styles.containerWrapper}>
        <Container fluid className='m-0 pl-0 pr-0 pt-5 pb-5'>
          <C {...props} />
        </Container>

        <div className={styles.containerPush}></div>
      </div>

      <div className={styles.footer}>
        <img height={100} src={connectedAppImg} alt='Xero connected app' />
        <img height={100} src={xeroDeveloper} alt='Xero developer' />
      </div>
    </>
  )
}

export default Layout
