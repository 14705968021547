import config from 'config'
import { getJsonHeaders, getQueryStringFromFiltersObject } from './helpers'

export async function getStats(query = {}) {
  let url = config.API_URL + '/iapi/home'

  const queryString = getQueryStringFromFiltersObject(query)

  if (queryString) {
    url += `?${queryString}`
  }

  const result = await fetch(url, {
    headers: await getJsonHeaders(),
  })

  return {
    success: result.status === 200,
    status: result.status,
    body: await result.json(),
  }
}

export async function getInvoiceStatsByDateRange(year, month, query = {}) {
  let url = config.API_URL + `/iapi/invoices-by-date-range/${year}/${month}`

  const queryString = getQueryStringFromFiltersObject(query)

  if (queryString) {
    url += `?${queryString}`
  }

  const result = await fetch(url, {
    headers: await getJsonHeaders(),
  })

  return {
    success: result.status === 200,
    status: result.status,
    body: await result.json(),
  }
}
